import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import ButtonImportModules from '../components/PropertiesPaneButtonImportModules.jsx'
import ArchPropertiesPane from '../_ARCH/ArchPropertiesPane.jsx'

export default function PropertiesPaneFa(props) {
    return (
        <ArchPropertiesPane
            buttons={
                <>
                    <ButtonExportModel global type="fm" />
                    <ButtonExportModel global type="fm_excel" />
                    <ButtonImportModules type="fm" />
                </>
            }

            {...props}
        />
    )
}
