import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Model from './ModelDes.mjs'
import ButtonExportReq from './PropertiesPaneButtonExportReq.jsx'

export default function PropertiesPaneDes(props) {
    return (
        <ProductPropertiesPane
            buttons={<ButtonExportReq />}

            controllers={
                <>
                    <Text prop="desNo" />
                    <Text prop="desTitle" />
                    <Text prop="taskNo" />
                </>
            }

            Model={Model}
            sn={null}
            {...props}
        />
    )
}
