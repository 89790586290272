import {Modal} from 'antd'

export const onDoubleClickNode = () => defaultBehaviours => {
    return async function (node, event) {
        event.stopPropagation()
        const {bizNodeType} = node.data

        try {
            await this.BizNode[bizNodeType].onDoubleClick(this, node, event)

            if (! event.defaultPrevented) {
                defaultBehaviours.onDoubleClickNode(node, event)
            }
        }
        catch (err) {
            Modal.error({content: err.message})
            throw err
        }
    }
}
