import MenuButton from '../../MenuButton.jsx'
import {useDiffMapContext} from './DiffMapContext.jsx'

export default function MenuButtonPrevDiff(props) {
    const {differenceCount, nextDiff} = useDiffMapContext()

    return (
        <MenuButton
            disabled={0 === differenceCount}
            onClick={nextDiff}
            {...props}
        >
            下一不同
        </MenuButton>
    )
}
