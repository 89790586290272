export const canReviseProduct = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canReviseProduct(node)
        )
    }
}

export const reviseProduct = () => () => {
    return async function (nodes) {
        let results

        await this.cmd(async () => {
            results = await this.runParallelAction(
                () => this.actions.reviseProduct(nodes),
                {action: '修订模型制品'},
            )
        })

        const isOk = results.some(
            ({status}) => 'rejected' !== status
        )

        if (isOk) {
            this.resetChangeHistory()
        }
    }
}
