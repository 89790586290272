import {useEffect, useLayoutEffect, useRef} from 'react'
import useFocusBack from '@/hooks/useFocusBack.mjs'
import useOnResize from '@/hooks/useOnResize.mjs'
import usePointer from '@/hooks/usePointer.mjs'
import {publish} from '@/script/event.mjs'
import {useTreeDocContext} from './TreeDocContext.jsx'
import CanvasSelection from './CanvasSelection.jsx'

const usePointerEvents = (doc) => usePointer({
    onClick: e => publish(doc, 'canvas_click', e),
    onDoubleClick: e => publish(doc, 'canvas_double_click', e),
    onPointerDown: e => publish(doc, 'canvas_pointer_down', e),
    onPointerMove: e => publish(doc, 'canvas_pointer_move', e),
    onPointerUp: e => publish(doc, 'canvas_pointer_up', e),
    onSwipeStart: e => publish(doc, 'canvas_swipe_start', e),
    onSwipeMove: e => publish(doc, 'canvas_swipe_move', e),
    onSwipeEnd: e => publish(doc, 'canvas_swipe_end', e),
})

export default function Canvas({children, ...props}) {
    const refEl = useRef()
    const doc = useTreeDocContext()
    useFocusBack(refEl)

    useOnResize(
        refEl,
        size => publish(doc, 'canvas_resize', size)
    )

    useLayoutEffect(
        () => {
            doc.canvas = refEl.current
            return () => doc.canvas = null
        },

        []
    )

    useEffect(
        () => {
            const el = refEl.current

            const handleWheel = e => {
                publish(doc, 'canvas_wheel', e)
            }

            el.addEventListener('wheel', handleWheel)
            return () => el.removeEventListener('wheel', handleWheel)
        },

        []
    )

    const pointerEventHandlers = usePointerEvents(doc)

    return (
        <div
            ref={refEl}
            role="tree"
            tabIndex="-1"
            onContextMenu={e => e.preventDefault()}
            {...pointerEventHandlers}
            {...props}
        >
            {children}
            <CanvasSelection />
        </div>
    )
}
