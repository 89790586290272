import {useEffect, useRef, useState} from 'react'
import {subscribe, unsubscribe} from '@/script/event.mjs'
import History from '@/script/History.mjs'

const setEqual = (a, b) => a.isSubsetOf(b) && b.isSubsetOf(a)

export default () => {
    const refHistory = useRef(new History)
    const refIsChanging = useRef(false)
    const h = refHistory.current

    const extensions = doc => {
        const change = async nodes => {
            refIsChanging.current = true
            await doc.execute(() => doc.selectNodes(nodes))
            refIsChanging.current = false
        }

        const currentIds = () => new Set(
            [...doc.selectedNodes].map(e => e.id)
        )

        const findRedoable= () => {
            if (- 1 === h.cursor) {
                return [- 1, []]
            }

            for (
                let i = 0 < doc.selectedNodes.size ? h.cursor + 1 : h.cursor;
                i < h.states.length;
                i += 1
            ) {
                const nodes = getNodes(h.states[i])

                if (0 < nodes.length) {
                    const ids = getIds(nodes)

                    if (! setEqual(ids, currentIds())) {
                        return [i, nodes]
                    }
                }
            }

            return [- 1, []]
        }

        const findUndoable = () => {
            for (
                let i = 0 < doc.selectedNodes.size ? h.cursor - 1 : h.cursor;
                - 1 < i;
                i -= 1
            ) {
                const nodes = getNodes(h.states[i])

                if (0 < nodes.length) {
                    const ids = getIds(nodes)

                    if (! setEqual(ids, currentIds())) {
                        return [i, nodes]
                    }
                }
            }

            return [- 1, []]
        }

        const getNodes = ids => {
            return [...ids]
                .map(id => doc.getNode(id))
                .filter(a => a)
        }

        const getIds = nodes => new Set(
            nodes.map(n => n.id)
        )

        return {
            get canRedoSelection() {
                const [i] = findRedoable()
                return - 1 < i
            },

            get canUndoSelection() {
                const [i] = findUndoable()
                return - 1 < i
            },

            async redoSelection() {
                const [i, nodes] = findRedoable()

                if (- 1 < i) {
                    h.cursor = i
                    await change(nodes)
                }
            },

            async undoSelection() {
                const [i, nodes] = findUndoable()

                if (- 1 < i) {
                    h.cursor = i
                    await change(nodes)
                }
            },

            useSelectionHistory() {
                const getHistoryStates = () => {
                    const canUndo = this.canUndoSelection
                    const canRedo = this.canRedoSelection
                    return {canRedo, canUndo}
                }

                const [states, setStates] = useState(getHistoryStates)

                useEffect(
                    () => {
                        const handleHistoryChange = () => {
                            setStates(getHistoryStates())
                        }

                        subscribe(h, 'change', handleHistoryChange)

                        return () => {
                            unsubscribe(h, 'change', handleHistoryChange)
                        }
                    },

                    []
                )

                return states
            }
        }
    }

    const watchers = {
        task_finish() {
            if (
                refIsChanging.current ||
                0 === this.selectedNodes.size
            ) {
                return
            }

            const ids = new Set(
                [...this.selectedNodes].map(e => e.id)
            )

            if (- 1 < h.cursor) {
                const lastIds = h.states[h.cursor]

                if (! setEqual(ids, lastIds)) {
                    h.push(ids)
                }
            }
            else {
                h.push(ids)
            }
        },
    }

    return {extensions, watchers}
}
