import {css} from '@emotion/react'
import _ToolBar from '../../ToolBar.jsx'
import {DiffMapProvider} from './DiffMapContext.jsx'
import DifferenceCount from './DifferenceCount.jsx'
import MapInfo from './MapInfo.jsx'
import Menu from './Menu.jsx'

const cssToolBar = css({
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    placeItems: 'start center',
})

export default function ToolBar({diffMap, ...props}) {
    return (
        <_ToolBar
            css={cssToolBar}
            {...props}
        >
            <DiffMapProvider value={diffMap}>
                <MapInfo map={diffMap.leftMap} />

                <div>
                    <Menu />
                    <DifferenceCount />
                </div>

                <MapInfo map={diffMap.rightMap} />
            </DiffMapProvider>
        </_ToolBar>
    )
}
