import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Empty, Form, Image, Input, Modal, Row, Space, Spin} from 'antd';
import Table from '@/pages/MainPage/components/Table';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import Page from '@/script/Page';
import {AppstoreOutlined, SearchOutlined, UnorderedListOutlined} from "@ant-design/icons";
import FileUtils from '@/script/FileUtils';
import SelCardPane from "@/pages/MainPage/panes/DesignProductTemplateLibrary/SelCardPane/SelCardPane";
import openWindow from '@/script/openWindow.mjs';

/**
 * SelectDptDialog: 选择制品模板弹窗
 * @param dpType        【必填】制品类型
 * @param sfTypeCode    【选填】系统功能类型（系统功能设计逻辑图导入模板时传入）
 * @param visible       【必填】当前Dialog是否展示
 * @param setVisible    【必填】当前Dialog展示的操作方法
 * @param onOk          【必填】回调函数
 * @returns {JSX.Element}
 * @constructor
 */
const SelectDptDialog = ({dpType, sfTypeCode, visible, setVisible, onOk, ...props}) => {
    const http = useHTTP();

    const ref = useRef({});

    // 表单
    const [form] = Form.useForm();

    // 数据模型
    const [dataList, setDataList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [expand, setExpand] = useState(false);

    // 模板图预览
    const [previewVisible, setPreviewVisible] = useState(false);

    // 设置显示方式
    const [listType, setListType] = useState('LIST');

    // 组件挂载执行一次
    useEffect(() => {
        if (visible) {
            form.resetFields();
            setListType('LIST');
            setSelectedRows([]);

            doSearch();
        }
    }, [visible]);

    const doSearch = async () => {
        try {
            setLoading(true);

            const formField = await form.validateFields();
            formField.dpType = dpType;
            formField.sfTypeCode = sfTypeCode;
            // 只能选择上架的模板
            formField.shelveFlag = true;

            const data = await runAsync(
                async () => {
                    return await http.get(`/dpts`, formField);
                }, {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                });

            setDataList(data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const doReset = async () => {
        doSearch();
        setSelectedRows([]);
        form.resetFields();
    }

    const handleOk = async () => {
        if (selectedRows.length === 1) {
            const mapData = await runAsync(
                async () => {
                    return await http.get(`/dpts/mapdata`, {
                        dpId: selectedRows[0].dpId,
                        dpType: dpType
                    });
                }, {
                    error: {content: (err) => `获取制品模板地图数据失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                });

            onOk(mapData);
        }

        doClose();
    }

    // 关闭Dialog
    const doClose = () => {
        form.resetFields();
        setVisible(false);
        setSelectedRows([]);
    }

    const rowSelection = {
        type: 'radio',
        selectedRowKeys: selectedRows.map(({pkid}) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    }


    // 详情Frame
    const linkRow = (record) => {
        openWindow('/DptDetail', {dptId: record.pkid, dptNo: record.dptNo});
    }

    const columns = [
        {
            title: '模板图',
            dataIndex: 'dptPicUrl',
            key: 'dptPicUrl',
            render: (text, record) => {
                if (record.dptPicId) {
                    return <Image
                        preview={{
                            visible: false,
                        }}
                        width="50px" src={FileUtils.getFileUrl(record.dptPicId)}
                        onClick={() => previewPic(record.dptPicId)}/>;
                } else {
                    return <div>暂无</div>;
                }
            }
        },
        {
            title: '模板编号',
            dataIndex: 'dptNo',
            render: (text, record) => {
                return <a onClick={() => linkRow(record)}>{text}</a>;
            }
        },
        {
            title: '模板名称',
            dataIndex: 'dptName',
        },
        {
            title: '模板项目',
            dataIndex: 'prjNo',
        },
        {
            title: '制品编号',
            dataIndex: 'dpSn',
        },
        {
            title: '模板标签',
            dataIndex: 'dptLabel',
        },
    ];

    const previewPic = (picId) => {
        if (picId) {
            ref.current.dptPicId = picId;

            setPreviewVisible(true);
        }
    }

    const closePreviewPic = (closeFlag) => {
        ref.current.dptPicId = null;
        setPreviewVisible(false);
    }


    return (
        <Modal
            visible={visible}
            width={1000}
            title='选择制品模板'
            okText="确认"
            cancelText="取消"
            onOk={handleOk}
            onCancel={() => setVisible(false)}
            bodyStyle={{background: '#f0f2f5', padding: '12px'}}
            {...props}
        >
            {/* 查询条件 */}
            <div style={{padding: '24px 24px 0 24px', overflow: 'hidden', background: '#fff'}}>
                <Form form={form} onFinish={doSearch}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item name="dptNo" label="模板编号">
                                <Input size="middle" placeholder="请输入"/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="dptName" label="模板名称">
                                <Input size="middle" placeholder="请输入"/>
                            </Form.Item>
                        </Col>

                        <Col span={12} offset={expand ? 18 : 0} style={{textAlign: 'right'}}>
                            <Button onClick={doReset} style={{margin: '0 8px'}}>重置</Button>
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>查询</Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div style={{marginTop: '12px', overflow: 'auto', height: '400px', background: '#fff'}}>
                {/* 按钮区 */}
                <div style={{
                    padding: '12px',
                    //display: 'flex',
                    //justifyContent: 'space-between',
                    textAlign: 'right'
                }}>
                    <Space>
                        {listType == 'LIST' && <Button
                            icon={<AppstoreOutlined/>}
                            onClick={() => setListType('PIC')}
                        ></Button>}
                        {listType == 'PIC' && <Button
                            icon={<UnorderedListOutlined/>}
                            onClick={() => setListType('LIST')}
                        ></Button>}
                    </Space>
                </div>

                {/* 数据表格 */}
                {listType == 'LIST' &&
                    <div style={{marginTop: '0px', overflow: 'auto', background: '#fff'}}>
                        {/* 数据区 */}
                        <div style={{marginBottom: '0'}}>
                            <Table
                                size="middle"
                                loading={loading}
                                columns={columns}
                                dataSource={dataList}
                                pagination={Page.init(dataList)}
                                rowKey={record => record.pkid}
                                rowSelection={{type: "checkbox", ...rowSelection}}
                            />
                        </div>

                        <div
                            style={{
                                display: 'none',
                            }}
                        >
                            <Image.PreviewGroup
                                preview={{
                                    visible: previewVisible,
                                    onVisibleChange: (vis) => closePreviewPic(vis),
                                }}
                            >
                                {ref.current.dptPicId &&
                                    <Image
                                        src={import.meta.env.VITE_HTTP_BASE_URL + '/files/' + ref.current.dptPicId}/>}
                            </Image.PreviewGroup>
                        </div>
                    </div>
                }
                {listType == 'PIC' &&
                    <Spin spinning={loading}>
                        {
                            dataList && dataList.length > 0 &&
                            <SelCardPane data={dataList} selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>
                        }

                        {
                            (!dataList || dataList.length == 0) && <Empty style={{alignSelf: 'center'}}/>
                        }
                    </Spin>
                }
            </div>
        </Modal>
    );
}

export default SelectDptDialog;
