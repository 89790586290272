import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaBdlogicInput.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        isHidden(map, node) {
            if (node.parent) {
                const {ruleType} = node.parent.data

                if ('INF' !== ruleType) {
                    return true
                }

                for (const n of node.parent.trueChain) {
                    const {bizNodeType} = n.data

                    if ('UE' === bizNodeType) {
                        return true
                    }
                }
            }

            return false
        },
    })
}
