/* eslint-disable react/jsx-key */

import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import IconButton from './icon-fopr-button.svg?react'
import IconCheck from './icon-fopr-check.svg?react'
import IconInput from './icon-fopr-input.svg?react'
import IconLink from './icon-fopr-link.svg?react'
import IconMenu from './icon-fopr-menu.svg?react'
import IconTab from './icon-fopr-tab.svg?react'

export default [
    [
        [
            'GENERAL',
            '通用',
            <IconLetters fill={Color.BLUE} />
        ],

        [
            'MENU',
            '点击菜单',
            <IconMenu fill={Color.BLUE} />
        ],

        [
            'BUTTON',
            '点击按钮',
            <IconButton fill={Color.BLUE} />
        ],

        [
            'CLICK_URL',
            '点击链接',
            <IconLink fill={Color.BLUE} />
        ],

        [
            'CLICK_TAB',
            '点击页签',
            <IconTab fill={Color.BLUE} />
        ],

        [
            'SHORTCUT',
            '快捷键',

            <IconLetters
                textColor={Color.DARK_GOLD}
                letters="K"
                fill={Color.BLUE}
            />
        ],

        [
            'CHECK_ROW',
            '勾选行记录',
            <IconCheck fill={Color.BLUE} />
        ],

        [
            'INPUT',
            '录入数据',
            <IconInput fill={Color.BLUE} />
        ],
    ],
]
