import {useEffect, useRef, useState} from 'react'
import {subscribe, unsubscribe} from '@/script/event.mjs'
import History from '@/script/History.mjs'

export default () => {
    const refHistory = useRef(new History)
    const refIsChanging = useRef(false)
    const refLastSelectedNodeIds = useRef([])
    const h = refHistory.current

    const change = async (doc, f, selectedNodeIds) => {
        refIsChanging.current = true

        await doc.execute(() => {
            f()

            const selectedNodes = selectedNodeIds.map(
                id => doc.getNode(id)
            )

            doc.selectNodes(selectedNodes)
        })

        refIsChanging.current = false
    }

    const extensions = () => ({
        get canRedoChange() {
            return h.cursor + 1 < h.states.length
        },

        get canUndoChange() {
            return - 1 < h.cursor
        },

        async redoChange() {
            if (! this.canRedoChange) {
                return
            }

            h.cursor += 1
            const [instructions, selectedNodeIds] = h.state

            await change(
                this,
                () => this.do(instructions),
                selectedNodeIds
            )
        },

        resetChangeHistory() {
            h.reset()
        },

        async undoChange() {
            if (! this.canUndoChange) {
                return
            }

            const [instructions, , selectedNodeIds] = h.state
            h.cursor -= 1

            await change(
                this,
                () => this.revert(instructions),
                selectedNodeIds
            )
        },

        useChangeHistory() {
            const getHistoryState = () => {
                const canUndo = this.canUndoChange
                const canRedo = this.canRedoChange
                return {canRedo, canUndo}
            }

            const [state, setState] = useState(getHistoryState)

            useEffect(
                () => {
                    const handleHistoryChange = () => {
                        setState(getHistoryState())
                    }

                    subscribe(h, 'change', handleHistoryChange)

                    return () => {
                        unsubscribe(h, 'change', handleHistoryChange)
                    }
                },

                []
            )

            return state
        }
    })

    const getSelectedNodeIds = doc => [...doc.selectedNodes].map(n => n.id)

    const watchers = {
        commit(instructions) {
            if (refIsChanging.current) {
                return
            }

            h.push([
                instructions,
                getSelectedNodeIds(this),
                refLastSelectedNodeIds.current,
            ])
        },

        task_start() {
            refLastSelectedNodeIds.current = getSelectedNodeIds(this)
        },
    }

    return {extensions, watchers}
}
