import {publish} from '@/script/event.mjs'

export default class History {
    #cursor = - 1
    #states = []

    get cursor() {
        return this.#cursor
    }

    set cursor(cursor) {
        if (cursor === this.#cursor) {
            return
        }

        if (
            - 1 <= cursor &&
            cursor < this.#states.length
        ) {
            this.#cursor = cursor
            publish(this, 'change', this)
        }
        else {
            throw new RangeError
        }
    }

    get state() {
        return this.#states[this.#cursor]
    }

    get states() {
        return this.#states.slice()
    }

    push(state) {
        this.#cursor += 1
        this.#states[this.#cursor] = state
        this.#states.length = this.#cursor + 1
        publish(this, 'change', this)
    }

    reset() {
        if (- 1 < this.#cursor) {
            this.#states.length = 0
            this.#cursor = - 1
            publish(this, 'change', this)
        }
    }
}
