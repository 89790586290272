import {css} from '@emotion/react'

import {
    NodeText as NodeTextCore,
    useMapContext,
} from '@/components/MindMap/index.mjs'

import NodeExtraText from './NodeExtraText.jsx'

const cssText = css({
    display: 'flex',
})

const cssTextHidden = css({
    '&:not(:only-child)': {
        height: 0,
    },
})

const cssTextMarked = css({
    textDecoration: 'underline wavy crimson',
})

const cssTextCore = css({
    maxWidth: 'unset',
})

const cssTextPrefix = css({
    marginRight: '.5em',
})

const cssTextSuffix = css({
    marginLeft: '.5em',
})

export default function NodeText({node, ...props}) {
    const map = useMapContext()
    map.useNodeChange(node, ['data'])
    const isEditingText = map.useIsEditingText(node)
    const isMarked = map.useIsNodeMarked(node)
    const text = map.getNodeText(node)
    const prefix = map.getNodeTextPrefix(node)
    const suffix = map.getNodeTextSuffix(node)
    const csssText = [cssText]

    if (isMarked) {
        csssText.push(cssTextMarked)
    }

    if (! (text || prefix || suffix || isEditingText)) {
        csssText.push(cssTextHidden)
    }

    return (
        <div
            css={csssText}
            {...props}
        >
            <NodeExtraText
                css={cssTextPrefix}
                node={node}
                text={prefix}
            />

            <NodeTextCore
                css={cssTextCore}
                node={node}
            />

            <NodeExtraText
                css={cssTextSuffix}
                node={node}
                text={suffix}
            />
        </div>
    )
}
