export default () => {
    const watchers = {
        canvas_swipe_start(e) {
            if (2 === e.buttons) {
                this.canvas.style.cursor = 'grabbing'
            }
        },

        canvas_swipe_move(e) {
            if (2 === e.buttons) {
                this.canvas.scrollBy({
                    behavior: 'instant',
                    left: - e.movementX,
                    top: - e.movementY,
                })
            }
        },

        canvas_swipe_end(e) {
            if (2 === e.buttons) {
                this.canvas.style.cursor = ''
            }
        },
    }

    return {watchers}
}
