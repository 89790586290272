import {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'

export default () => {
    const refSelectedNodes = useRef(new Set())
    const selectedNodes = refSelectedNodes.current

    const extensions = () => ({
        selectedNodes,

        selectNodes(nodes) {
            let isChanged = false
            const nodesToSelect = new Set(nodes)

            for (const node of selectedNodes) {
                if (! nodesToSelect.has(node)) {
                    selectedNodes.delete(node)
                    isChanged = true

                    if (! node.isDeleted) {
                        publish(node, 'selected_change', false)
                    }
                }
            }

            for (const node of nodes) {
                if (! selectedNodes.has(node)) {
                    selectedNodes.add(node)
                    isChanged = true

                    for (const n of this.chain(node.parent)) {
                        n.isFolded = false
                    }

                    if (! node.isDeleted) {
                        publish(node, 'selected_change', true)
                    }
                }
            }

            if (isChanged) {
                publish(this, 'selected_nodes_change', selectedNodes)
            }
        },

        useIsNodeSelected(node) {
            const [isSelected, setIsSelected] = useState(
                () => selectedNodes.has(node)
            )

            useLayoutEffect(
                () => {
                    subscribe(node, 'selected_change', setIsSelected)

                    return () => {
                        unsubscribe(node, 'selected_change', setIsSelected)
                    }
                },

                [node]
            )

            return isSelected
        },

        useSelectedNodes() {
            const [, setFlag] = useState(false)

            useEffect(
                () => {
                    const handleChange = () => {
                        setTimeout(
                            () => setFlag(flag => ! flag)
                        )
                    }

                    subscribe(this, 'selected_nodes_change', handleChange)

                    return () => unsubscribe(
                        this,
                        'selected_nodes_change',
                        handleChange
                    )
                },

                []
            )

            return selectedNodes
        },
    })

    const watchers = {
        model_change() {
            let isChanged = false

            for (const node of selectedNodes) {
                if (node.isDeleted) {
                    if (selectedNodes.has(node)) {
                        isChanged = true
                        selectedNodes.delete(node)
                    }
                }
            }

            if (isChanged) {
                publish(this, 'selected_nodes_change', selectedNodes)
            }
        },
    }

    return {extensions, watchers}
}
