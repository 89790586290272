import {publish, subscribe} from '@/script/event.mjs'
import * as task from '@/script/task.mjs'
import Tree from '@/script/Tree.mjs'
import TreeDocNode from './TreeDocNode.mjs'

const isNodeRef = prop => {
    return /^(parent|(first|last)Child|(prev|next)Sibling)$/.test(prop)
}

const Instruction = {
    CREATE: 0,
    DELETE: 1,
    UPDATE: 2,
}

export default class TreeDoc extends Tree {
    static TreeNode = TreeDocNode

    constructor() {
        super()

        subscribe(this, 'task_execute', () => {
            if (0 === this.#changes.size) {
                return
            }

            const changeChildren = (node) => {
                if (! node) {
                    return
                }

                let changes = this.#changes.get(node)

                if (! changes) {
                    changes = new Map
                    this.#changes.set(node, changes)
                }

                const type = 'children'
                changes.set(type, {node, type})
            }

            for (const [node, changes] of this.#changes) {
                const parentChange = changes.get('parent')

                if (parentChange) {
                    changeChildren(parentChange.oldValue)
                    changeChildren(parentChange.newValue)
                }
                else if (
                    changes.has('isDeleted') ||
                    changes.has('isHidden') ||
                    changes.has('prevSibling') ||
                    changes.has('nextSibling')
                ) {
                    changeChildren(node.parent)
                }
            }

            // 上一步会产生新的变更，需要再次迭代
            for (const [node, changes] of this.#changes) {
                publish(node, 'change', changes)
            }

            publish(this, 'model_change', this.#changes)
            this.#instructions.push([])
            this.#resetChanges()
        })

        subscribe(this, 'task_execute_fail', () => {
            const instructions = this.#compressInstructions(
                this.#instructions.pop()
            )

            if (0 < instructions.length) {
                this.revert(instructions)
            }

            this.#instructions.push([])
            this.#resetChanges()
        })

        subscribe(this, 'task_fail', () => {
            const instructions = this.#instructions.flat()

            if (0 === instructions.length) {
                return
            }

            const insts = this.#compressInstructions(instructions)

            if (0 < insts.length) {
                this.revert(insts)
            }

            this.#resetInstructions()
            this.#resetChanges()
        })

        subscribe(this, 'task_finish', () => {
            const instructions = this.#instructions.flat()

            if (0 === instructions.length) {
                return
            }

            const insts = this.#compressInstructions(instructions)

            if (0 < insts.length) {
                publish(this, 'commit', insts)
            }

            this.#resetInstructions()
        })
    }

    deleteTree(node) {
        super.deleteTree(node)

        if (node.parent) {
            node.parent.descendantCount -= node.descendantCount + 1
        }
    }

    async init(tree) {
        super.init(tree)
        await this._init()

        // 初始化产生的变更和指令不应保留
        this.#resetChanges()
        this.#resetInstructions()
    }

    do(instructions) {
        // 存放不需要的临时指令
        this.#instructions.push([])

        const ops = [
            id => this.#doInstructionCreate(id),
            id => this.#doInstructionDelete(id),
            ([id, prop, value]) => this.#doInstructionUpdate(id, prop, value)
        ]

        for (const [type, data] of instructions) {
            ops[type](data)
        }

        // 放弃产生的临时指令
        this.#instructions.pop()
    }

    revert(instructions) {
        // 存放不需要的临时指令
        this.#instructions.push([])

        const reverse = function* (arr) {
            for (let i = arr.length - 1; -1 < i; i -= 1) {
                yield arr[i]
            }
        }

        // 建立节点间联系之前先恢复被删除的节点
        for (const [type, data] of reverse(instructions)) {
            if (1 === type) {
                this.#doInstructionCreate(data)
            }
        }

        for (const [type, data] of reverse(instructions)) {
            if (0 === type) {
                this.#doInstructionDelete(data)
            }
            else if (2 === type) {
                const [id, prop, , oldValue] = data
                this.#doInstructionUpdate(id, prop, oldValue)
            }
        }

        // 放弃产生的临时指令
        this.#instructions.pop()
    }

    async execute(fn) {
        await task.execute(this, fn)
    }

    failTask() {
        task.fail(this)
    }

    finishTask() {
        task.finish(this)
    }

    startTask() {
        task.start(this)
    }

    _addNode(node) {
        super._addNode(node)
        this._changeNode(node, 'isCreated')
        this.#log(Instruction.CREATE, node.id)
    }

    _changeNode(node, prop, newValue, oldValue) {
        if (
            void 0 !== newValue &&
            newValue === oldValue
        ) {
            return
        }

        if (! this.#changes.has(node)) {
            this.#changes.set(node, new Map)
        }

        const changes = this.#changes.get(node)
        const change = changes.get(prop)

        if (change) {
            if (
                // both undefined
                newValue !== oldValue &&
                // change back
                newValue === change.oldValue
            ) {
                changes.delete(prop)

                if (0 === changes.size) {
                    this.#changes.delete(node)
                }
            }
            else {
                changes.set(prop, {newValue, node, oldValue: change.oldValue})
            }
        }
        else {
            if (
                'isDeleted' === prop &&
                changes.has('isCreated')
            ) {
                this.#changes.delete(node)
            }
            else {
                changes.set(prop, {newValue, node, oldValue})
            }
        }
    }

    _constructNode(id) {
        const {TreeNode} = this.constructor
        return new TreeNode(this, id)
    }

    _deconstructNode(node) {
        super._deconstructNode(node)

        Object.assign(node, {
            isFolded: false,
            isHidden: false,
        })
    }

    _removeNode(node) {
        super._removeNode(node)
        this._changeNode(node, 'isDeleted')
        this.#log(Instruction.DELETE, node.id)
    }

    _updateNode(node, prop, newValue, oldValue) {
        this._changeNode(node, prop, newValue, oldValue)

        this.#log(
            Instruction.UPDATE,

            [
                node.id,
                prop,
                this.#serializeNodeProp(prop, newValue),
                this.#serializeNodeProp(prop, oldValue),
            ]
        )
    }

    async _init() {
    }

    /**
     * 记录单次任务执行时节点的变更
     */
    #changes = new Map

    /**
     * 记录任务开始/结束期间每次执行产生的指令
     */
    #instructions = [[]]

    #deserializeNodeProp(prop, value) {
        if (isNodeRef(prop)) {
            if (value instanceof this.constructor.TreeNode) {
                return value
            }
            else {
                const node = this.getNode(value)
                return node ?? value
            }
        }
        else {
            return value
        }
    }

    #doInstructionCreate(id) {
        const node = this._constructNode(id)
        this._addNode(node)
    }

    #doInstructionDelete(id) {
        const node = this.getNode(id)
        this._removeNode(node)
    }

    #doInstructionUpdate(id, prop, value) {
        const node = this.getNode(id)
        node[prop] = this.#deserializeNodeProp(prop, value)
    }

    #log(type, data) {
        this.#instructions.at(-1).push([type, data])
    }

    #serializeNodeProp(prop, value) {
        if (isNodeRef(prop)) {
            return value && value.id
        }
        else {
            return value
        }
    }

    #compressInstructions(instructions) {
        const created = new Map
        const updated = new Map

        for (const [i, [type, data]] of instructions.entries()) {
            [
                // CREATE
                id => created.set(id, i),

                // DELETE
                id => {
                    const ic = created.get(id)

                    // 如果创建过该节点，把该节点的指令全部丢弃
                    if (ic || 0 === ic) {
                        instructions[i].push(true)
                        instructions[ic].push(true)

                        const updates = updated.get(id)

                        if (updates) {
                            for (const iu of updates.values()) {
                                instructions[iu].push(true)
                            }
                        }
                    }
                },

                // UPDATE
                ([id, prop, newValue]) => {
                    const updates = updated.get(id)

                    if (updates) {
                        const iu = updates.get(prop)

                        // 如果修改过该节点该属性
                        if (iu || 0 === iu) {
                            const [, inst] = instructions[iu]
                            const [, , , oldValue] = inst

                            // 这次修改的新值等于上次修改的旧值
                            if (newValue === oldValue) {
                                // 丢弃这次和上次修改
                                instructions[iu].push(true)
                                instructions[i].push(true)
                            }
                            else {
                                // 合并这次和上次修改
                                instructions[i].push(true)
                                inst[2] = newValue
                            }
                        }
                        else {
                            updates.set(prop, i)
                        }
                    }
                    else {
                        const updates = new Map
                        updates.set(prop, i)
                        updated.set(id, updates)
                    }
                },
            ][type](data)
        }

        // 删除全部标记为丢弃的指令
        return instructions.filter(e => ! e[2])
    }

    #resetChanges() {
        this.#changes = new Map
    }

    #resetInstructions() {
        this.#instructions = [[]]
    }
}
