import {memo, useRef, useState} from 'react'
import {css} from '@emotion/react'
import {useTreeDocContext} from '@/components/TreeDoc/index.mjs'

const COLOR_SELECTED = 'rgba(233, 84, 32, 1)'
const COLOR_HOVERED = 'rgba(233, 84, 32, .3)'

const cssNodeOuterBox = css({
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    outline: '2px solid transparent',
    outlineOffset: 2,
    pointerEvents: 'auto',

    '&:hover': {
        outlineColor: COLOR_HOVERED,
    },
})

const useStyle = (map, node) => {
    const [rect, setRect] = useState({width: 0, height: 0})
    const [shape, paddings] = map.getNodeShape(node, rect)

    const style = {
        backgroundImage: `url('${shape}')`,
        padding: paddings.map((n) => `${n}px`).join(' '),
    }

    return [style, setRect]
}

const NodeOuterBox = ({node, ...props}) => {
    const map = useTreeDocContext()
    const refEl = useRef()
    map.useRegisterDom('nodes', node, refEl)
    map.useNodeChange(node, ['data'])
    const isEditingText = map.useIsEditingText(node)
    const isSelected = map.useIsNodeSelected(node)
    const title = map.getNodeTitle(node)

    const handleClick = (e) => {
        map.behaviours.onClickNode(node, e)
    }

    const handleContextMenu = (e) => {
        e.preventDefault()
        map.behaviours.onContextMenu(node, e)
    }

    const handleDoubleClick = (e) => {
        map.behaviours.onDoubleClickNode(node, e)
    }

    const handlePointerDown = (e) => {
        e.stopPropagation()

        // 阻止输入框失焦
        if (isEditingText) {
            e.preventDefault()
        }
    }

    const [style, onInnerBoxResize] = useStyle(map, node)

    if (isSelected) {
        Object.assign(style, {outlineColor: COLOR_SELECTED})
    }

    return (
        <div
            ref={refEl}
            css={cssNodeOuterBox}
            style={style}
            title={title}
            onClick={handleClick}
            onContextMenu={handleContextMenu}
            onDoubleClick={handleDoubleClick}
            onPointerDown={handlePointerDown}
            {...props}
        >
            <map.components.TreeJoint node={node} />

            <map.components.NodeInnerBox
                node={node}
                onResize={onInnerBoxResize}
            />
        </div>
    )
}

const MemorizedNodeOuterBox = memo(NodeOuterBox, () => true)
MemorizedNodeOuterBox.displayName = 'NodeOuterBox'

export default MemorizedNodeOuterBox
