import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaPhys.mjs'
import Model from './ModelPhys.mjs'
import PropertiesPane from './PropertiesPanePhys.jsx'
import FormChoose from './FormChoosePhys.jsx'
import TableChoose from './TableChoosePhys.jsx'
import api from './apiPhys.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    borderColor={Color.DARK_GREEN}
                    letters="|||"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    borderColor: Color.DARK_GREEN,
                }),
            }
        },

        getTextPrefix(map, node) {
            const defaultPrefix = ProductNode.getTextPrefix.call(
                this, map, node
            )

            const {psEnv} = node.data

            if (psEnv) {
                return `${defaultPrefix}【${psEnv}】`
            }
            else {
                return defaultPrefix
            }
        },

        async gotoRev(map, node, rev, target) {
            await this.gotoCurrent(map, node, target)
        },

        _getCategories(map, node) {
            return [
                'PHYS_DEPLOYED_ON',
            ]
        },

        async _grow(map, node, depth) {
            const newDepth = await ProductNode._grow.call(
                this, map, node, depth
            )

            if (newDepth === depth) {
                return depth
            }

            const p = node.trueParent

            if (p) {
                const {bizNodeType} = p.data

                if ('CAT_ROOT_PRJ_DEP' === bizNodeType) {
                    for (const n of [...node.children]) {
                        if ('PHYS_DEPLOYED_ON' === n.data.bizNodeType) {
                            map.deleteNode(n)
                        }
                        else {
                            map.deleteTree(n)
                        }
                    }
                }
            }

            return newDepth
        },

        async _readGrowTree(map, node) {
            const {pkid} = node.data
            return this.readTree({pkid})
        },
    })
}
