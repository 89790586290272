import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import ArchPropertiesPane from '../_ARCH/ArchPropertiesPane.jsx'

export default function PropertiesPanePa(props) {
    return (
        <ArchPropertiesPane
            buttons={<ButtonExportModel global type="pm" />}
            {...props}
        />
    )
}
