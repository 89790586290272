import {memo, useRef} from 'react'
import {css} from '@emotion/react'
import useOnResize from '@/hooks/useOnResize.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import NodeDeliveryVersion from './NodeDeliveryVersion.jsx'
import NodeDesc from './NodeDesc.jsx'
import NodeIcons from './NodeIcons.jsx'
import NodeOwner from './NodeOwner.jsx'
import NodeProject from './NodeProject.jsx'
import NodeText from './NodeText.jsx'
import NodeVersion from './NodeVersion.jsx'

const cssNodeInnerBox = css({
    display: 'grid',

    gridTemplate: `
        "icons desc desc ver dVer"
        "icons owner owner ver dVer"
        "icons text text  ver dVer"
        "icons prj  prj   prj prj" / auto auto 1fr auto auto auto
    `,

    placeItems: 'center start',
    position: 'relative',
    padding: '.4em .5em',
})

const cssNodeIcons = css({
    gridArea: 'icons',
})

const cssNodeDesc = css({
    gridArea: 'desc',
})

const cssNodeOwner = css({
    gridArea: 'owner',
})

const cssNodeText = css({
    gridArea: 'text',
})

const cssNodeVersion = css({
    gridArea: 'ver',
    alignSelf: 'end',
})

const cssNodeDeliveryVersion = css({
    gridArea: 'dVer',
    alignSelf: 'end',
})

const cssNodeProject = css({
    gridArea: 'prj',
    alignSelf: 'end',
})

const NodeInnerBox = ({node, onResize, ...props}) => {
    const map = useMapContext()
    map.useNodeChange(node, ['data'])
    const refEl = useRef()
    useOnResize(refEl, onResize)

    return (
        <div
            ref={refEl}
            css={cssNodeInnerBox}
            {...props}
        >
            <NodeIcons
                css={cssNodeIcons}
                node={node}
            />

            <NodeDesc
                css={cssNodeDesc}
                node={node}
            />

            <NodeOwner
                css={cssNodeOwner}
                node={node}
            />

            <NodeText
                css={cssNodeText}
                node={node}
            />

            <NodeVersion
                css={cssNodeVersion}
                node={node}
            />

            <NodeDeliveryVersion
                css={cssNodeDeliveryVersion}
                node={node}
            />

            <NodeProject
                css={cssNodeProject}
                node={node}
            />
        </div>
    )
}

const MemorizedNodeInnerBox = memo(NodeInnerBox, () => true)
MemorizedNodeInnerBox.displayName = 'NodeInnerBox'

export default MemorizedNodeInnerBox
