import {useEffect, useMemo, useRef, useState} from 'react'
import {subscribe, unsubscribe} from '@/script/event.mjs'

const getCompareIdToNode = (map) => {
    const compareIdToNode = new Map()

    const next = (chain) => {
        const {mapCompUuid} = chain[0].data

        if (! mapCompUuid) {
            return {
                yieldChildren: false,
                yieldNode: false,
            }
        }
    }

    for (const node of map.dfs(map.root, next)) {
        const {mapCompUuid} = node.data

        if (! compareIdToNode.has(mapCompUuid)) {
            compareIdToNode.set(mapCompUuid, node)
        }
    }

    return compareIdToNode
}

export default (leftMap, rightMap, differences) => {
    const [currentDifference, setCurrentDifference] = useState(0)
    const refCompareIdToNode = useRef({})
    const refLockSelectNode = useRef(false)
    const differenceCount = differences.length

    const compareIdToDifferenceIndex = useMemo(
        () => {
            const c2i = new Map()

            for (const [i, [, idL, idR]] of differences.entries()) {
                c2i.set(idL ?? idR, i)
            }

            return c2i
        },

        [differences]
    )

    useEffect(
        () => {
            if (! (leftMap && rightMap && 0 < differenceCount)) {
                return
            }

            refCompareIdToNode.current.l = getCompareIdToNode(leftMap)
            refCompareIdToNode.current.r = getCompareIdToNode(rightMap)

            const handleSelectNodes = (map) => {
                return async (selectedNodes) => {
                    if (
                        1 !== selectedNodes.size ||
                        refLockSelectNode.current
                    ) {
                        return
                    }

                    const [node] = selectedNodes
                    const {mapCompUuid: cid} = node.data

                    if (! cid) {
                        return
                    }

                    const {_compareIdToDiffType} = map.data
                    const diffType = _compareIdToDiffType.get(cid)

                    if (diffType) {
                        const i = compareIdToDifferenceIndex.get(cid)
                        await selectDiff(i)
                    }
                }
            }

            const handleSelectNodesL = handleSelectNodes(leftMap)
            const handleSelectNodesR = handleSelectNodes(rightMap)
            subscribe(leftMap, 'selected_nodes_change', handleSelectNodesL)
            subscribe(rightMap, 'selected_nodes_change', handleSelectNodesR)

            return () => {
                unsubscribe(leftMap, 'selected_nodes_change', handleSelectNodesL)
                unsubscribe(rightMap, 'selected_nodes_change', handleSelectNodesR)
            }
        },

        [leftMap, rightMap]
    )

    const selectDiff = async (diffIndex) => {
        const [type, idL, idR] = differences[diffIndex]
        const c2n = refCompareIdToNode.current

        if (! (c2n.l && c2n.r)) {
            return
        }

        const [nodeL, nodeR] = ({
            ADD: () => {
                const nodeR = c2n.r.get(idR)
                const {mapCompUuid: pcid} = nodeR.parent.data
                const nodeL = c2n.l.get(pcid)
                nodeL.isFolded = false
                return [nodeL, nodeR]
            },

            DEL: () => {
                const nodeL = c2n.l.get(idL)
                const {mapCompUuid: pcid} = nodeL.parent.data
                const nodeR = c2n.r.get(pcid)
                nodeR.isFolded = false
                return [nodeL, nodeR]
            },

            UPT: () => [
                c2n.l.get(idL),
                c2n.r.get(idR),
            ],
        }[type])()

        refLockSelectNode.current = true

        await Promise.all([
            leftMap.execute(() => leftMap.selectNodes([nodeL])),
            rightMap.execute(() => rightMap.selectNodes([nodeR])),
        ])

        refLockSelectNode.current = false
        setCurrentDifference(diffIndex)
    }

    const nextDiff = async () => {
        if (0 === differences.length) {
            return
        }

        if (currentDifference + 1 < differences.length) {
            await selectDiff(currentDifference + 1)
        }
        else {
            await selectDiff(0)
        }
    }

    const prevDiff = async () => {
        if (0 === differences.length) {
            return
        }

        if (0 < currentDifference) {
            await selectDiff(currentDifference - 1)
        }
        else {
            await selectDiff(differences.length - 1)
        }
    }

    return {
        currentDifference,
        differenceCount,
        leftMap,
        nextDiff,
        prevDiff,
        rightMap,
        selectDiff,
    }
}
