import {Button} from 'antd'
import http from '@/biz/http.mjs'
import useAlert from '@/biz/useAlert.jsx'
import runAsync from '@/script/runAsync.mjs'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

const PropertiesPaneButtonExportReq = (props) => {
    const alert = useAlert()
    const [{pkid: desId, prjId, sVer}] = useProperties()

    if (! desId) {
        return null
    }

    const handleClick = async () => {
        await runAsync(
            () => http.post('/prd/export/despr', {desId, prjId, sVer}),
            {action: '导出产品需求'}
        )

        alert.generatingFile()
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            导出产品需求
        </Button>
    )
}

export default PropertiesPaneButtonExportReq
