import {css} from '@emotion/react'
import {Button} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssToolBar = css({
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
    padding: 8,
})

export default function ToolBar({items, selectedKeys, ...props}) {
    const map = useMapContext()
    const permission = map.usePermission()

    const upgradeNodes = nodes => {
        map.commands.upgradeProduct(nodes)
    }

    const handleClickUpgrade = () => {
        const nodes = [...selectedKeys].map(
            key => map.getNode(key)
        )

        upgradeNodes(nodes)
    }

    const handleClickUpgradeAll = () => {
        const nodes = map.commands.canUpgradeProduct(items)
        upgradeNodes(nodes)
    }

    if (! permission.has('write')) {
        return null
    }

    return (
        <div
            css={cssToolBar}
            {...props}
        >
            <Button
                disabled={0 === selectedKeys.size}
                size="small"
                onClick={handleClickUpgrade}
            >升级</Button>

            <Button
                disabled={0 === items.length}
                size="small"
                onClick={handleClickUpgradeAll}
            >全部升级</Button>
        </div>
    )
}
