import {css} from '@emotion/react'
import {useDiffMapContext} from './DiffMapContext.jsx'

const cssDifferenceCount = css({
    textAlign: 'center',
})

export default function DifferenceCount(props) {
    const {currentDifference, differenceCount} = useDiffMapContext()
    const text = differenceCount ?
        `${currentDifference + 1} / ${differenceCount}`
        :
        '两张地图完全相同'

    return (
        <div
            css={cssDifferenceCount}
            {...props}
        >
            {text}
        </div>
    )
}
