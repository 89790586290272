import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'taskNo',
    detailUrl: '/DesDetail',
    detailIdProp: 'desId',
    mapProp: 'desMap',
    name: '开发任务单',
    textProp: 'desTitle',
    type: 'DES',
}
