import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaBdlogicUi.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        getText(map, node) {
            return '人机交互\n要求'
        },

        isHidden(map, node) {
            if (node.parent) {
                const {ruleType} = node.parent.data

                if ('BT' !== ruleType) {
                    return true
                }

                for (const n of node.parent.trueChain) {
                    const {bizNodeType} = n.data

                    if ('UE' === bizNodeType) {
                        return true
                    }
                }
            }
            else {
                return false
            }
        },
    })
}
