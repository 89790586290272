import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    desNo: {
        name: () => '开发任务单编号',
        readOnly: () => true,
    },

    desTitle: {
        name: () => '标题',
    },

    taskNo: {
        name: () => '业务需求号',
    },
})
