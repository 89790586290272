import {useEffect, useState} from 'react'
import {css} from '@emotion/react'
import BaseMap from '../../BaseMap.jsx'
import useDiffMap from './useDiffMap.mjs'
import useMapData from './useMapData.mjs'
import ToolBar from './ToolBar/ToolBar.jsx'

const cssDiffMap = css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto 1fr',
    overflow: 'hidden',
})

const cssToolBar = css({
    gridColumn: 'span 2',
})

const components = {ToolBar: () => null}

const DiffMap = ({
    children,
    differences,
    inits,
    left,
    right,
    ...props
}) => {
    const [leftMap, setLeftMap] = useState()
    const [rightMap, setRightMap] = useState()
    const diffMap = useDiffMap(leftMap, rightMap, differences)

    const selectFirstDifference = (
        0 < differences.length &&
        void 0 === inits?.selectedNodes
    )

    useEffect(
        () => {
            if (leftMap && rightMap && selectFirstDifference) {
                diffMap.selectDiff(0)
            }
        },

        [leftMap, rightMap, selectFirstDifference]
    )

    const _inits = selectFirstDifference ?
        {...inits, selectedNodes: false} : inits

    return (
        <div
            css={cssDiffMap}
            {...props}
        >
            <ToolBar
                css={cssToolBar}
                diffMap={diffMap}
            />

            <BaseMap
                components={components}
                initData={left}
                inits={_inits}
                onLoad={setLeftMap}
            />

            <BaseMap
                components={components}
                initData={right}
                inits={_inits}
                onLoad={setRightMap}
            />
        </div>
    )
}

export default function DiffMapWrapper({
    basMapVerId,
    id,
    inits,
    leftRev,
    rightRev,
    tarMapVerId,
    type,
    onLoad,
    ...props
}) {
    const [left, right, differences] = useMapData(
        id, type, leftRev, rightRev, basMapVerId, tarMapVerId
    )

    useEffect(
        () => {
            if (left && right && differences) {
                onLoad?.({differences, left, right})
            }
        },

        [left, right, differences]
    )

    if (! (left && right && differences)) {
        return null
    }

    return (
        <DiffMap
            differences={differences}
            left={left}
            right={right}
            {...props}
        />
    )
}
