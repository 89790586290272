import {memo, useRef} from 'react'
import {css} from '@emotion/react'
import useOnResize from '@/hooks/useOnResize.mjs'
import {useTreeDocContext} from '@/components/TreeDoc/index.mjs'

const cssNodeInnerBox = css({
    position: 'relative',
    padding: '.4em .5em',
})

const NodeInnerBox = ({node, onResize, ...props}) => {
    const map = useTreeDocContext()
    const refEl = useRef()
    useOnResize(refEl, onResize)

    return (
        <div
            css={cssNodeInnerBox}
            ref={refEl}
            {...props}
        >
            <map.components.NodeText node={node} />
        </div>
    )
}

const MemorizedNodeInnerBox = memo(NodeInnerBox, () => true)
MemorizedNodeInnerBox.displayName = 'NodeInnerBox'

export default MemorizedNodeInnerBox
