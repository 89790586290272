import {useEffect, useState} from 'react'
import {TreeDocument} from '@/components/TreeDoc/index.mjs'
import {subscribeOnce} from '@/script/event.mjs'
import defaultHotkeys from './hotkeys.mjs'
import useMindMap from './useMindMap.mjs'
import Canvas from './Canvas.jsx'
import Children from './Children.jsx'
import Node from './Node.jsx'
import NodeInnerBox from './NodeInnerBox.jsx'
import NodeOuterBox from './NodeOuterBox.jsx'
import NodeText from './NodeText.jsx'
import Tree from './Tree.jsx'
import TreeJoint from './TreeJoint.jsx'

const MindMap = function MindMap({
    children,
    components,
    Doc,
    hotkeys,
    initData,
    plugins,
    onLoad,
    ...props
}) {
    const [isLoaded, setIsLoaded] = useState(false)

    const map = useMindMap({
        components: {
            Canvas,
            Children,
            Node,
            NodeInnerBox,
            NodeOuterBox,
            NodeText,
            Tree,
            TreeJoint,
            ...components,
        },

        Doc,
        initData,
        plugins,
    })

    useEffect(
        () => {
            const handleLoad = () => {
                setIsLoaded(true)
                onLoad?.(map)
            }

            subscribeOnce(map, 'load', handleLoad)
        },

        []
    )

    map.useRegisterHotkeys({...defaultHotkeys, ...hotkeys})

    return (
        <TreeDocument
            doc={map}
            {...props}
        >
            {isLoaded && children}
        </TreeDocument>
    )
}

export default function MindMapWrapper(props) {
    if (! props.initData) {
        return null
    }

    return <MindMap {...props} />
}
