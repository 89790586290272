import {useMapContext} from '@/components/MindMap/index.mjs'
import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Bm from '../BM/PropertiesControllerBm.jsx'
import Pm from '../PM/PropertiesControllerPm.jsx'
import BfType from './BfType.mjs'
import Model from './ModelBf.mjs'

export default function PropertiesPaneBf(props) {
    const map = useMapContext()
    const {subTypeCode} = map.data
    const module = 'BODA2' === subTypeCode ? <Pm /> : <Bm />

    return (
        <ProductPropertiesPane
            controllers={
                <>
                    {module}
                    <Text prop="bfNo" />
                    <Text prop="bfName" />

                    <Select
                        options={BfType}
                        prop="bfTypeCode"
                    />

                    <LongText prop="bfDesc" />
                    <LongText prop="bfMemo" />
                </>
            }

            Model={Model}

            snSuffix={
                <Analyse
                    modes={[
                        'ChgReference',
                        'Reference',
                        'ReferenceCall',
                    ]}
                />
            }

            {...props}
        />
    )
}
