import {Button} from 'antd'
import http from '@/biz/http.mjs'
import useAlert from '@/biz/useAlert.jsx'
import runAsync from '@/script/runAsync.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

const types = {
    bdlogic: [
        '业务事务能力',
        '/prd/export/sa/bm/bdlogic',
        'bmId',
        'bmCode',
    ],

    bdrule: [
        '业务规则',
        '/prd/export/sa/bm/bdrule',
        'bmId',
        'bmCode',
    ],

    bdsummary: [
        '用户场景',
        '/prd/export/sa/bm/bdsummary',
        'bmId',
        'bmCode',
    ],

    bdterm: [
        '业务术语',
        '/prd/export/sa/bm/bdterm',
        'bmId',
        'bmCode',
    ],

    bf: [
        '业务流程',
        '/prd/export/sa/bm/bf',
        'bmId',
        'bmCode',
    ],

    bm: [
        '业务模型',
        '/prd/export/sa/bm',
        'bmId',
        'bmCode',
    ],

    des: [
        '产品需求',
        '/prd/export/despr',
        'desId',
        'desCode',
    ],

    fm: [
        '功能模型',
        '/prd/export/sa/sf',
        'fmId',
        'fmCode',
    ],

    fm_excel: [
        '功能模型清单Excel版',
        '/prd/export/sa/sf/excel',
        'fmId',
        'fmCode',
    ],

    pm: [
        '流程模型',
        '/prd/export/sa/pm',
        'pmId',
        'pmCode',
    ],
}

export default function PropertiesPaneButtonExportModel({
    global = false,
    type,
    ...props
}) {
    const alert = useAlert()
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (! (
        1 === selectedNodes.size &&
        'SA_MAP' === map.data.mapTypeCode
    )) {
        return null
    }

    const [name, url, idProp, codeProp] = types[type]

    const handleClick = async () => {
        const [node] = selectedNodes

        const {
            [codeProp]: code,
            pkid,
            prjId,
            sVer,
        } = node.data

        const args = {prjId, sVer}

        if (! global) {
            Object.assign(args, {
                [codeProp]: code,
                [idProp]: pkid,
            })
        }

        await runAsync(
            () => http.post(url, args),
            {action: `导出${name}`}
        )

        alert.generatingFile()
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            导出{name}
        </Button>
    )
}
