import MenuButton from '../../MenuButton.jsx'
import {useDiffMapContext} from './DiffMapContext.jsx'

const ButtonCompare = ({leftMap, rightMap, ...props}) => {
    const leftSelectedNodes = leftMap.useSelectedNodes()
    const rightSelectedNodes = rightMap.useSelectedNodes()

    const extraProps = (() => {
        if (
            1 !== leftSelectedNodes.size ||
            1 !== rightSelectedNodes.size
        ) {
            return {disabled: true}
        }

        const [l] = leftSelectedNodes
        const [r] = rightSelectedNodes

        if (! (l.parent && r.parent)) {
            return {disabled: true}
        }

        const {bizNodeType: bntL, pkid: idL} = l.data
        const {bizNodeType: bntR, pkid: idR} = r.data

        if (idL !== idR) {
            return {disabled: true}
        }

        const revL = leftMap.BizNode[bntL].getRev(leftMap, l)
        const revR = rightMap.BizNode[bntR].getRev(rightMap, r)

        if (revL === revR) {
            return {disabled: true}
        }

        const onClick = async e => {
            const target = e.altKey ? '_self' : '_blank'
            leftMap.BizNode[bntL].gotoDiff(leftMap, l, revL, revR, target)
        }

        return {onClick}
    })()

    return (
        <MenuButton
            {...extraProps}
            {...props}
        >
            模件版本比对
        </MenuButton>
    )
}

export default function MenuButtonCompareProduct(props) {
    const {leftMap, rightMap} = useDiffMapContext()

    if (! (leftMap && rightMap)) {
        return null
    }

    return (
        <ButtonCompare
            leftMap={leftMap}
            rightMap={rightMap}
            {...props}
        />
    )
}
