import {css} from '@emotion/react'
import {Checkbox} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssList = css({
    overflow: 'auto',
})

const cssListItem = css({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: 8,
    padding: 8,

    '&:hover': {
        backgroundColor: '#e9f7fe',
    },
})

const cssListItemProductName = css({
    fontSize: 16,
})

const cssListItemProductVersion = css({
})

const ListItem = ({node, ...props}) => {
    const map = useMapContext()
    const {lastRev, rev, sVer} = node.data
    const text = map.getNodeText(node)
    const prefix = map.getNodeTextPrefix(node)
    const suffix = map.getNodeTextSuffix(node)
    const title = [prefix, text, suffix].join('') || '(空白)'
    const latest = `${sVer}.${lastRev}`
    const version = `${sVer}.${rev}`
    const _n = map.nodeProxy(node)
    const canUpgrade = _n.canUpgrade()

    const handleClick = () => {
        map.execute(
            () => map.selectNodes([node])
        )
    }

    return (
        <div
            css={cssListItem}
            {...props}
        >
            <Checkbox disabled={! canUpgrade} />

            <div onClick={handleClick}>
                <div css={cssListItemProductName}>{title}</div>
                <div css={cssListItemProductVersion}>{version}→{latest}</div>
            </div>
        </div>
    )
}

export default function UpgradableNodes({
    items,
    selectedKeys,
    onSelect,
    ...props
}) {
    const handleChangeItem = (key) => {
        return ({target: {checked}}) => {
            const newSelectedKeys = new Set(selectedKeys)

            if (checked) {
                newSelectedKeys.add(key)
            }
            else {
                newSelectedKeys.delete(key)
            }

            onSelect(newSelectedKeys)
        }
    }

    const listItems = items.map(
        node => (
            <ListItem
                key={node.id}
                node={node}
                onChange={handleChangeItem(node.id)}
            />
        )
    )

    return (
        <div
            css={cssList}
            {...props}
        >
            {listItems}
        </div>
    )
}
