import {useEffect, useRef} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'
import TreeDoc from './TreeDoc.mjs'

const useInstallExtentions = (doc, extensions) => {
    const refKey2descBak = useRef(new Map)

    for (const [key, desc] of refKey2descBak.current) {
        if (desc) {
            Reflect.defineProperty(doc, key, desc)
        }
        else {
            Reflect.deleteProperty(doc, key)
        }
    }

    refKey2descBak.current = new Map

    for (const install of extensions) {
        const ext = install(doc)

        if (! ext) {
            continue
        }

        for (const key of Object.keys(ext)) {
            const desc = Reflect.getOwnPropertyDescriptor(ext, key)

            for (const k of ['get', 'set', 'value']) {
                if ('function' === typeof desc[k]) {
                    desc[k] = desc[k].bind(doc)
                }
            }

            if (! refKey2descBak.current.has(key)) {
                if (Object.hasOwn(doc, key)) {
                    refKey2descBak.current.set(
                        key,
                        Reflect.getOwnPropertyDescriptor(doc, key)
                    )
                }
                else {
                    refKey2descBak.current.set(key, null)
                }
            }

            Reflect.defineProperty(doc, key, desc)
        }
    }
}

const useInstallWatchers = (doc, watchers) => {
    useEffect(
        () => {
            const listeners = watchers
                .map(watcher => {
                    return Object.entries(watcher).map(
                        ([eventType, handler]) => [
                            eventType,
                            handler.bind(doc)
                        ]
                    )
                })
                .flat()

            for (const [eventType, listener] of listeners) {
                subscribe(doc, eventType, listener)
            }

            return () => {
                for (const [eventType, listener] of listeners) {
                    unsubscribe(doc, eventType, listener)
                }
            }
        },

        [watchers]
    )
}

const useInstallPlugins = (doc, plugins) => {
    const extensions = []
    const watchers = []

    for (const {extensions: e, watchers: w} of plugins) {
        if (e) {
            // 允许后安装的扩展覆盖先安装的扩展
            extensions.push(e)
        }

        if (w) {
            // 允许后安装的监听器取消事件以避免触发先安装的监听器
            watchers.unshift(w)
        }
    }

    useInstallExtentions(doc, extensions)
    useInstallWatchers(doc, watchers)
}

export default ({
    Doc = TreeDoc,
    initData = {root: {}},
    plugins = [],
} = {}) => {
    const refDoc = useRef()
    refDoc.current ??= new Doc
    useInstallPlugins(refDoc.current, plugins)

    useEffect(
        () => {
            (async () => {
                const doc = refDoc.current
                await doc.init(initData?.root)

                // 等待事件监听注册结束
                setTimeout(() => publish(doc, 'load'))
            })()
        },

        []
    )

    return refDoc.current
}
