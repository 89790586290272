import {useMapContext} from '@/components/MindMap/index.mjs'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import ButtonImportModules from '../components/PropertiesPaneButtonImportModules.jsx'
import ArchPropertiesPane from '../_ARCH/ArchPropertiesPane.jsx'

export default function PropertiesPaneBa(props) {
    const map = useMapContext()
    const {mapTypeCode, subTypeCode} = map.data
    const [node] = map.useSelectedNodes()

    const buttons = (() => {
        if ('SA_MAP' === mapTypeCode) {
            for (const n of map.trueChain(node.parent)) {
                const {bizNodeType} = n.data

                if (bizNodeType !== node.data.bizNodeType) {
                    if ('CAT_DATA' === bizNodeType) {
                        return null
                    }
                }
            }
        }

        if ('BODA2' === subTypeCode) {
            return (
                <>
                    <ButtonExportModel global type="bm" />
                    <ButtonExportModel global type="bdterm" />
                    <ButtonExportModel global type="bdrule" />
                    <ButtonExportModel global type="bdsummary" />
                    <ButtonExportModel global type="bdlogic" />
                    <ButtonImportModules global type="bm" />
                </>
            )
        }
        else {
            return (
                <>
                    <ButtonExportModel global type="bm" />
                    <ButtonExportModel global type="bf" />
                    <ButtonExportModel global type="bdterm" />
                    <ButtonExportModel global type="bdrule" />
                    <ButtonExportModel global type="bdsummary" />
                    <ButtonExportModel global type="bdlogic" />
                    <ButtonImportModules global type="bm" />
                </>
            )
        }
    })()

    return (
        <ArchPropertiesPane
            buttons={buttons}
            {...props}
        />
    )
}
